//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Profile',
    components: {
        MyFriends: () => import('@/components/User/MyFriends.vue'),
        ProfileCoverPhoto: () => import('@/components/ProfileCoverPhoto.vue') ,
        ProfileNamestrip: () => import('@/components/Profile/ProfileNamestrip.vue'),
        AchievementsList: () => import('@/components/Achievements/AchievementsList.vue'),
        ProfileDashboard: () => import('@/components/Profile/ProfileDashboard.vue'),
        ProfileDetails: () => import('@/components/Profile/ProfileDetails.vue'),
        ProfileFriendRequest: () => import('@/components/Profile/ProfileFriendRequest'),
        PostContainer: () => import('@/components/Posts/PostContainer.vue'),
        Gallery: () => import('@/components/Gallery/Gallery'),
        LoadingSkeletonProfile: () => import('@/components/LoadingSkeletonProfile')
    },
    data() {
        return {
            isOwner: false,
            isFriends: false,
            isLoading: true,
            profile: [],
            title: '',
            isPreview: true,
            coverPhoto:  require('@/assets/default_cover2.webp'),
            profilePhoto:  require('@/assets/default_profile.webp')
        }
    },
    metaInfo(){
      return{
          title: this.title
      }
    },
    methods: {
        fullContent(){
            return {
                'isFullContent': this.isFullContent,
            }
        },
        ishidden(){
            return{
                'hidden': this.isFullContent
            }
        },
        loadProfile() {
            this.$q.loading.show();
            this.$store.commit('profile/SwitchState','posts')
            this.$store.dispatch('user/loadProfile' , this.currentUserId)
            .then((status) => {
                this.isLoading = false;
                if (status.message == 'setup_required'){
                    if(status.user_id == localStorage._uid){
                        this.$router.push({name:'ProfileSetup'})
                    }else{
                        this.isLoading = false;
                        this.$router.push({name:'PageNotFound'})
                    }   
                }
                if (status.message == 'profile_retrieved') {
                    this.isOwner = status.isOwner;
                    this.isFriends = status.isFriends;
                    this.profile   = status.profile;
                    this.title = this.profile.first_name + ' ' + this.profile.last_name
                    this.$store.commit('profile/UpdateStateProfilePhoto',status.profilePhoto)
                    this.isLoading = false;
                    if (status.coverPhoto != 'not_available') {
                        this.$store.commit('profile/UpdateStateCoverPhoto', status.coverPhoto)
                        this.coverPhoto = this.$store.state.profile.cover_photo;
                        if(this.isOwner){
                            this.$store.commit('user/updateProfileCoverPhoto',this.$store.state.profile.cover_photo)
                        }
                    }else{
                        this.coverPhoto = '@/assets/default_cover2.webp';
                    }
                    if(status.coverPhoto == '@/assets/default_cover.jpg'){
                         if(this.isOwner){
                            this.$store.commit('user/updateProfileCoverPhoto',status.coverPhoto)
                        }
                    }
                    if (status.profilePhoto != 'not_available') {
                        this.profilePhoto = status.profilePhoto;
                    }
                }
                if (status.message == 'profile_not_found') {
                    this.$router.push({name: 'NotFound404'});
                }
            })
            this.$q.loading.hide();
        }
    },
    created() {
        this.loadProfile();    
    },
    watch: {
        '$route.params.id' () {
            window.location.reload();
        },
    },
    computed: {
        getFullName() {
            return this.profile.first_name + ' ' + this.profile.last_name
        },
        currentUserId() {
            return this.$route.params.id == null ? this.$store.state.user.id : this.$route.params.id
        },
        isFullContent(){
            return this.$store.state.profile.board_state != 'posts' ? true : false
        }
    },
}
